import React from "react";
import styles from './AboutCompany.module.scss';

const AboutCompany = () => {
  return(
    <div className={styles.aboutCompany}>
      <div className={styles.aboutCompanyTitle}>О нас</div>
      <div className={styles.aboutCompanyText}>
        <b>ООО «ТранспроектИнжиниринг»</b> – команда профессионалов в области транспортного
        планирования и комплексного проектирования автомобильных дорог и объектов транспортной инфраструктуры.
      </div>
      <div className={styles.aboutCompanyText}>
        Сотрудники компании имеют многолетний опыт успешной работы с государственными заказчиками и частными инвесторами
        на объектах транспортного строительства Северо-Западного, Центрального, Приволжского федеральных округов, Краснодарского края, Крыма, Сибири и Дальнего Востока.
      </div>
      <div className={styles.aboutCompanyText}>
        <b>Наша задача</b> – обеспечение успешной реализации проекта на всех этапах его жизненного цикла, выработка грамотных технологических и конструктивных решений в партнерстве
        с заказчиком и подрядчиком, а также результативная защита и сопровождение принятых проектных решений в контрольно-надзорных органах, экологической и государственной экспертизе.
      </div>
      <div className={styles.aboutCompanyText}>
        <b>Наша миссия</b> – созидательная деятельность, способствующая развитию транспортной инфраструктуры, повышению качества жизни людей, защите окружающей среды. Мы работаем командой профессионалов,
        опираясь на накопленный опыт, уделяя особое внимание вовлеченности и достижениям каждого сотрудника.
      </div>
    </div>
  );
}

export default React.memo(AboutCompany);