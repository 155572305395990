import React from "react";
import styles from './NewsItem.module.scss';

const NewsItem = ({imageURL, date, text}) => {
  return(
    <div className={styles.newsItem}>
      <div className={styles.newsItemImageBlock}>
        {imageURL.map((obj) => 
          <img className={styles.newsItemImage} src={obj} alt="image" />
        )}
      </div>
      <div className={styles.newsItemTextBlock}>
        <div className={styles.newsItemDate}>{date}</div>
        <div className={styles.newsItemText} dangerouslySetInnerHTML={{ __html: text }}></div>
      </div>
    </div>
  );
}

export default React.memo(NewsItem);